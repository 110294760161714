import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { EmailComposer } from '@ionic-native/email-composer/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { MenuComponent } from './components/menu/menu.component';
import { ToasterDestinationsComponent } from './components/toaster-destinations/toaster-destinations.component';
import { ToasterShareComponent } from './components/toaster-share/toaster-share.component';
import { PointComponent } from './components/point/point.component';
import { SlidesPublicationsComponent } from './components/slides-publications/slides-publications.component';
import { SlidesRoutesComponent } from './components/slides-routes/slides-routes.component';
import { ModalMapComponent } from './components/modal-map/modal-map.component';
import { OfflineComponent } from './components/offline/offline.component';
import { MapDesktopComponent } from './components/map-desktop/map-desktop.component';
import { MenuDesktopComponent } from './components/menu-desktop/menu-desktop.component';

import { FormsModule } from "@angular/forms";

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent, 
    MenuComponent, 
    ToasterDestinationsComponent, 
    ToasterShareComponent, 
    PointComponent, 
    SlidesPublicationsComponent,
    SlidesRoutesComponent,
    ModalMapComponent,
    OfflineComponent,
    MapDesktopComponent,
    MenuDesktopComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    HttpClientModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxPK,
      geocoderAccessToken: environment.mapboxPK
    }),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),
    FormsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, EmailComposer, OneSignal],
  bootstrap: [AppComponent],
})
export class AppModule {}
