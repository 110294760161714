import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { Gesture, GestureController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
})
export class PointComponent implements OnInit {

  @Input() point: any;
  @ViewChild('modal', { read: ElementRef }) modal: ElementRef;
  @ViewChild('swipebar', { read: ElementRef }) swipebar: ElementRef;

  limitPixels: number = 150;
  limitVelocity: number = 0.4;
  offsetDownAction: number = 150;
  expandModal: boolean = false;

  deviceID: string;

  pointPublications: any = [];
  pointRoutes: any = [];

  isDesktop: boolean = false;

  constructor(private platform: Platform, private apiService: ApiService, private changeDetectorRef: ChangeDetectorRef, private gestureCtrl: GestureController, private mapService: MapService) { }

  ngOnInit() {}

  async getDeviceId() {
    const deviceKey = await Storage.get({ key: 'stats_devices_id' });
    const device = JSON.parse(deviceKey.value);
    this.deviceID = String(device.stats_devices_id);
  }

  async ngOnChanges() {
    if (!this.deviceID) {
      await this.getDeviceId();
    }

    if (this.point.description.length > 100) {
      this.point.descriptionCropped = this.point.description.substring(0, 100) + '...';
    } else {
      this.point.descriptionCropped = this.point.description;
    }

    this.pointPublications = [];
    this.pointRoutes = [];

    if (this.point.point_id) {
      this.point.variations = [];
      this.apiService.getPoint(this.deviceID, this.point.point_id).subscribe(
        data => {
          this.point.variations = [];
          if (data.data.point) {
            let point = data.data.point;
            this.point.variations.push(point);
          }
          if (data.data.variations) {
            let variations = data.data.variations;
            variations.forEach(element => {
              if (element.id !== this.point.id) {
                this.point.variations.push(element);
              }
            });
          }
        },
        err => {
          console.log(err);
        }
      );
      if (this.point.publication_count) {
        this.apiService.getPointPublications({variation_id: this.point.id}).subscribe(
          data => {
            this.pointPublications = data.data;
          },
          err => {
            console.log(err);
          }
        );
      }
      if (this.point.trajet_count) {
        this.apiService.getPointRoutes({variation_id: this.point.id}).subscribe(
          data => {
            this.pointRoutes = data.data;
          },
          err => {
            console.log(err);
          }
        );
      }
    } else {
      if (this.point.publication_count) {
        this.apiService.getPointPublications({point_id: this.point.id}).subscribe(
          data => {
            this.pointPublications = data.data;
          },
          err => {
            console.log(err);
          }
        );
      }
      if (this.point.trajet_count) {
        this.apiService.getPointRoutes({point_id: this.point.id}).subscribe(
          data => {
            this.pointRoutes = data.data;
          },
          err => {
            console.log(err);
          }
        );
      }
    }

    this.mapService.getNewPointFromMap().subscribe(
      data => {
        this.onVariationClick(data);
      },
      err => {
        console.log(err);
      }
    );
  }

  ngAfterViewInit() {
    if (this.platform.is('desktop') || window.innerWidth > 800) {
      this.isDesktop = true;
      this.onExpandDesktop();
    } else {
      this.isDesktop = false;
      const gesture = this.gestureCtrl.create({
        el: this.modal.nativeElement,
        threshold: 0,
        gestureName: 'move',
        onStart: ev => {
          if (this.expandModal) {
            if (ev.startY < this.offsetDownAction) {
              this.swipebar.nativeElement.style.opacity = `0.4`;
            }
          } else {
            this.swipebar.nativeElement.style.opacity = `0.4`;
          }
        },
        onMove: ev => {
          if (this.expandModal) {
            if (ev.startY < this.offsetDownAction) {
              this.onMove(ev);
            }
          } else {
            this.onMove(ev);
          }
        },
        onEnd: ev => {
          this.swipebar.nativeElement.style.opacity = `1`;
          if (this.expandModal) {
            if (ev.startY < this.offsetDownAction) {
              this.onEndMove(ev);
            }
          } else {
            this.onEndMove(ev);
          }
        }
      });
    
      gesture.enable(true);
    }
  }
  
  onMove(ev) {
    const deltaY = ev.deltaY;
    if (deltaY < 0 && !this.expandModal) {
      let offset = Math.abs(deltaY);
      this.modal.nativeElement.style.height = `calc(300px + ${offset}px)`;
    } else if (deltaY > 0) {
      this.modal.nativeElement.style.transform = `translateY(${deltaY}px)`;
    }
  }
  
  onEndMove(ev) {
    const velocityY = Math.abs(ev.velocityY);
    const deltaY = ev.deltaY;

    if (!this.expandModal) {
      if (velocityY > this.limitVelocity) {
        if (deltaY < 0) {
          this.onExpand();
        } else {
          this.onClose();
        }
      } else if (deltaY < -this.limitPixels) {
        this.onExpand();
      } else if (deltaY > this.limitPixels) {
        this.onClose();
      } else {
        this.onReset();
      }
    } else {
      if (velocityY > this.limitVelocity && deltaY > 0) {
        this.onClose();
      } else if (deltaY > this.limitPixels) {
        this.onClose();
      } else {
        this.onResetExpand();
      }
    }

  }

  onExpand(scroll?) {
    this.modal.nativeElement.style.height = `calc(100vh - 120px)`;
    this.modal.nativeElement.style.transform = `translateY(0px)`;
    setTimeout(() => {
      this.expandModal = true;
      this.changeDetectorRef.detectChanges();
      if (scroll) {
        const el = document.getElementById(scroll);
        setTimeout(() => {
          el.scrollIntoView({behavior: 'smooth'});
        }, 100);
      }
    }, 1);
  }

  onExpandDesktop() {
    this.modal.nativeElement.style.height = `100vh`;
    this.modal.nativeElement.style.height = `-webkit-fill-available`;
    this.modal.nativeElement.style.transform = `translateY(0px)`;
    setTimeout(() => {
      this.expandModal = true;
      this.changeDetectorRef.detectChanges();
    }, 1);
  }

  onReset() {
    this.modal.nativeElement.style.height = `300px`;
    this.modal.nativeElement.style.transform = `translateY(0px)`;
  }

  onResetExpand() {
    this.modal.nativeElement.style.transform = `translateY(0px)`;
  }

  onClose() {
    this.modal.nativeElement.style.transform = `translateY(120%)`;
    setTimeout(() => {
      this.mapService.showPoint('close');
    }, 100);
  }

  onNavigate() {
    const lat = this.point.lat;
    const lon = this.point.lon;
    const url = "https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=" + lat + "," + lon;
    window.open(url, '_blank');
  }

  onVariationClick(variation) {
    this.modal.nativeElement.style.transform = `translateY(120%)`;
    setTimeout(() => {
      this.mapService.showNewPoint(variation);
    }, 100);
  }

  openModal(type) {
    switch (type) {
      case 1:
        this.onExpand('publications');
        break;
      case 2:
        this.onExpand('routes');
        break;
      case 3:
        this.onExpand('points');
        break;
      default:
        break;
    }
  }

}
