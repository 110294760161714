import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'publications',
    loadChildren: () => import('./publications/publications.module').then( m => m.PublicationsPageModule)
  },
  {
    path: 'publication/:publicationID/:publicationTitleFormatted',
    loadChildren: () => import('./publication/publication.module').then( m => m.PublicationPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'routes',
    loadChildren: () => import('./routes/routes.module').then( m => m.RoutesPageModule)
  },
  {
    path: 'route/:routeID/:routeTitleFormatted',
    loadChildren: () => import('./route/route.module').then( m => m.RoutePageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: '',
    redirectTo: 'routes',
    pathMatch: 'full'
  },
  {
    path: '**', 
    redirectTo: 'routes'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
