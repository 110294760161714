import { Component, Input, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ApiService } from 'src/app/services/api.service';
import { MapService } from 'src/app/services/map.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-map',
  templateUrl: './modal-map.component.html',
  styleUrls: ['./modal-map.component.scss'],
})
export class ModalMapComponent implements OnInit {

  @Input() point: any;
  @Input() deviceID: any;
  
  map: mapboxgl.Map;
  bounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();

  allMarkers: any = [];

  mapboxPK: string = environment.mapboxPK;

  constructor(private apiService: ApiService, private mapService: MapService) { }

  ngOnInit() {
    if (!this.point.point_id) {
      this.allMarkers.push(this.point);
      if (this.point.variations) {
        if (this.point.variations.length) {
          this.point.variations.forEach(element => {
            this.allMarkers.push(element);
          });
        }
      }
      this.initMap();
    } else {
      this.apiService.getPoint(this.deviceID, this.point.point_id).subscribe(
        data => {
          if (data.data.point) {
            let point = data.data.point;
            this.allMarkers.push(point);
          }
          if (data.data.variations) {
            let variations = data.data.variations;
            variations.forEach(element => {
              this.allMarkers.push(element);
            });
          }
          this.allMarkers.forEach((element,index) => {
            if (element.id == this.point.id) {
              this.allMarkers.splice(index, 1);
              this.allMarkers.unshift(element);
            }
          });
          this.initMap();
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map-point',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2.3522219, 48.856614],
      zoom: 3,
      accessToken: this.mapboxPK
    });

    this.allMarkers.forEach((marker, index) => {
      var el = document.createElement('div');
      if (marker.id !== this.point.id) {
        el.className = 'marker-not-current';
        el.id = 'num-' + String(Number(index));
      } else {
        el.className = 'marker';
      }
      if (!marker.description) marker.description = '';

      if (marker.customicon.length) {
        el.style.backgroundImage = "url('" + marker.customicon[0].url + "')";
      }

      let markerEl = new mapboxgl.Marker(el)
        .setLngLat([marker.lon, marker.lat])
        .addTo(this.map);

      if (marker.id !== this.point.id) {
        markerEl.getElement().addEventListener('click', () => {
          this.mapService.showNewPointFromMap(marker);
        });
      }

      this.bounds.extend([marker.lon, marker.lat]);
    });
    this.map.fitBounds(this.bounds, {maxZoom: 20, padding: 50, duration: 0});
  }

}
