import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { TranslatePipe } from '@ngx-translate/core';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-toaster-destinations',
  templateUrl: './toaster-destinations.component.html',
  styleUrls: ['./toaster-destinations.component.scss'],
  providers: [TranslatePipe]
})
export class ToasterDestinationsComponent implements OnInit {

  @Input() data: any;

  deviceID: any;
  publicationID: any;

  constructor(private statsService: StatsService, private commonService: CommonService) { }

  ngOnInit() {
    this.deviceID = this.data.deviceID;
    this.publicationID = this.data.publicationID;
  }

  onClose() {
    this.commonService.publishDestinations(
      'close'
    );
  }

  startTravel(point) {
    if (point.point_id) {
      this.statsService.addStatAction(this.deviceID, 'gmap', {article_id: this.publicationID, point_id: point.point_id, variation_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.statsService.addStatAction(this.deviceID, 'gmap', {article_id: this.publicationID, point_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
    const lat = point.lat;
    const lon = point.lon;
    const url = "https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=" + lat + "," + lon;
    window.open(url, '_blank')
  }

}
