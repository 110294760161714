import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';
import { CachingService } from './caching.service';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  user = new BehaviorSubject<User>(null);

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private cachingService: CachingService, private http: HttpClient) {}


  listPublications(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/home`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listPublicationsLight(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/home_light`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/home_light`, resData);
      })
    );
  }

  getAllPointsAndVariations(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/maps`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPoint(stats_devices_id: string, point_id: string, variation_id?: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('point_id', point_id);
    if (variation_id) form.set('variation_id', variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/points`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPublication(stats_devices_id: string, article_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('article_id', article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/publication/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/publication/get/${article_id}`, resData);
      })
    );
  }

  getPublications(ids: any, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('ids', ids);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/publications/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getRoute(stats_devices_id: string, route_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('route_id', route_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/route/get/${route_id}`, resData);
      })
    );
  }

  getRoutes(ids: any, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('ids', ids);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/routes/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listRoute(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listRoutesLight(stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/route/list_light`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}front/route/list_light`, resData, 31536000);
      })
    );
  }

  getMapboxRoute(profile, coordinates, accessToken) {
    let url = 'https://api.mapbox.com/directions/v5/mapbox/' + profile + '/' + coordinates + '?alternatives=true&geometries=geojson&overview=full&access_token=' + accessToken;
    return this.http.get<any>(
      url,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPointPublications(options: {point_id?: string, variation_id?: string}) {
    const form: FormData = new FormData();

    if (options.point_id) form.set('point_id', options.point_id);
    if (options.variation_id) form.set('variation_id', options.variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/point_publications`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getPointRoutes(options: {point_id?: string, variation_id?: string}) {
    const form: FormData = new FormData();

    if (options.point_id) form.set('point_id', options.point_id);
    if (options.variation_id) form.set('variation_id', options.variation_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/point_routes`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getConfig(options?: {apptitle?: string, lang?: string}) {
    const form: FormData = new FormData();

    if (options) {
      if (options.apptitle) form.set('apptitle', options.apptitle);
      if (options.lang) form.set('lang', options.lang);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}config/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}config/list`, resData);
      })
    );
  }

  getLangElements() {
    const form: FormData = new FormData();
    
    return this.http.post<any>(
      `${environment.baseApiUrl}lang_element/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        this.cachingService.cacheRequest(`${environment.baseApiUrl}lang_element/list`, resData);
      })
    );

  }

  getCategories(lang) {
    const form: FormData = new FormData();
    form.set('lang', lang);

    return this.http.post<any>(
      `${environment.baseApiUrl}catpoint/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  searchArticle(search: string, stats_devices_id: string) {
    const form: FormData = new FormData();
    form.set('string', search);
    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}front/articles_search`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }




  addAccount(email: string, password?: any, options?: {firstname?: string, lastname?: string, phone?: any, adresse?: string, zipcode?: any, city?: any}) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('password', password);
    if (options) {
      if (options.firstname) form.set('firstname', options.firstname);
      if (options.lastname) form.set('lastname', options.lastname);
      if (options.phone) form.set('phone', options.phone);
      if (options.adresse) form.set('adresse', options.adresse);
      if (options.zipcode) form.set('zipcode', options.zipcode);
      if (options.city) form.set('city', options.city);
    }
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editAccount(email: string, options?: {password?: any, firstname?: string, lastname?: string, phone?: any, adresse?: string, zipcode?: any, city?: any}) {
    const form: FormData = new FormData();
    form.set('email', email);
    if (options) {
      if (options.password) form.set('password', options.password);
      if (options.firstname) form.set('firstname', options.firstname);
      if (options.lastname) form.set('lastname', options.lastname);
      if (options.phone) form.set('phone', options.phone);
      if (options.adresse) form.set('adresse', options.adresse);
      if (options.zipcode) form.set('zipcode', options.zipcode);
      if (options.city) form.set('city', options.city);
    }
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getAccount(account_id: any) {
    const form: FormData = new FormData();
    form.set('account_id', account_id);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  login(email: any, password: any) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('password', password);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/connect`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        if (resData.data.account_id) {
          const user = new User(resData.data.account_id);
          this.user.next(user);
          localStorage.setItem('userData', JSON.stringify(user));
        }
      })
    );
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem('userData');
  }

  autoLogin() {
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    if (!userData) return;

    const loadedUser = new User(userData.id);

    if (loadedUser.id) {
      this.user.next(loadedUser);
    }
  }

  resetToken(email: any) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('url_reinit', `${environment.baseUrl}account?token=`);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/reset/token`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  resetPassword(email: any, token: any, password: any) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('token', token);
    form.set('password', password);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/reset/password`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  createSession(stats_devices_id: any, publicaccount_id: any, trajet_id: any, options?: {point_id?: any, variation_id?: any}) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('trajet_id', trajet_id);
    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
      if (options.variation_id) form.set('variation_id', options.variation_id);
    }
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/session/create`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getSession(stats_devices_id: any, publicaccount_id: any) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/session/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editSession(stats_devices_id: any, publicaccount_id: any, session_id: any, options?: {point_id?: any, variation_id?: any, trajet_id?: any}) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('session_id', session_id);
    if (options) {
      if (options.point_id) form.set('point_id', options.point_id);
      (options.variation_id) ? form.set('variation_id', options.variation_id) : form.set('variation_id', '');
      if (options.trajet_id) form.set('trajet_id', options.trajet_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/session/edit`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addScore(stats_devices_id: any, publicaccount_id: any, session_id: any, quizzquestion_id: any, is_goodanswer: any, point_id: any, score: any, options?: {variation_id?: any, key?: any, letter?: any}) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('session_id', session_id);
    form.set('quizzquestion_id', quizzquestion_id);
    (is_goodanswer) ? form.set('is_goodanswer', is_goodanswer) : form.set('is_goodanswer', '0');
    form.set('point_id', point_id);
    form.set('score', score);

    if (options) {
      if (options.variation_id) form.set('variation_id', options.variation_id);
      if (options.letter) form.set('letter', options.letter);
      if (options.key) form.set('key', options.key);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/score`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  noteTrajet(stats_devices_id: any, publicaccount_id: any, trajet_id: any, note: any) {
    const form: FormData = new FormData();
    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('trajet_id', trajet_id);
    form.set('note', note);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/trajet/note`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }
  
  getQuizz(quizz_ids: any) {
    const form: FormData = new FormData();

    form.set('user_id', '7');
    form.set('quizz_ids', quizz_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/back/route/quizz/get`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getArticlesTemplate(stats_devices_id: string, lang: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}v2/front/template/articles/get` + '?stats_devices_id=' + stats_devices_id + '&lang=' + lang,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getTrajetsTemplate(stats_devices_id: string, lang: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}v2/front/template/trajets/get` + '?stats_devices_id=' + stats_devices_id + '&lang=' + lang,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  likeArticle(stats_devices_id: any, publicaccount_id: any, article_id: any) {
    const form: FormData = new FormData();

    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('article_id', article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/article_likes/add`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlikeArticle(stats_devices_id: any, publicaccount_id: any, article_id: any) {
    const form: FormData = new FormData();

    form.set('stats_devices_id', stats_devices_id);
    form.set('publicaccount_id', publicaccount_id);
    form.set('article_id', article_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/article_likes/remove`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listMenuItem(stats_devices_id: any) {
    const form: FormData = new FormData();

    form.set('stats_devices_id', stats_devices_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/nav_elements/list`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  deleteAccount(password: any) {
    const form: FormData = new FormData();

    const jsonData = localStorage.getItem('userData');
    const account_id = JSON.parse(jsonData).id;    
    
    form.set('account_id', account_id);
    form.set('password', password);
    
    return this.http.post<any>(
      `${environment.baseApiUrl}v2/front/account/delete`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

}
