import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'src/app/services/map.service';
import { LoadingController } from '@ionic/angular';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-slides-publications',
  templateUrl: './slides-publications.component.html',
  styleUrls: ['./slides-publications.component.scss'],
})
export class SlidesPublicationsComponent implements OnInit {

  loading: any;

  @Input() point: any;
  @Input() pointPublications: any;

  @ViewChild('slider') slider;

  slideOpts: any = {
    freeMode: true,
    slidesPerView: 1.5,
    spaceBetween: 20
  };

  pointPublicationsFiltered: any = [];

  showModalConnect: boolean = false;
  user: any;
  errorMessage: any;

  loginFormToggled: boolean = true;
  registerFormToggled: boolean = false;

  publicationWaitingForLogin: any;
  
  constructor(private apiService: ApiService, private loadingController: LoadingController, private translateService: TranslateService, private mapService: MapService, private router: Router) { }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-loader'
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  ngOnInit() {
    this.apiService.user.subscribe(user => {
      if (user) {
        this.getUser(user.id);
      }
    });
    let lang = this.translateService.getBrowserLang();
    if (lang.startsWith('fr')) lang = 'fr_FR';
    if (lang.startsWith('en')) lang = 'en_GB';
    this.pointPublications.forEach(element => {

      if (element.lang == lang && (element.etat == 2 || element.etat == 3)) {
        this.createFormattedTitle(element);
        if (element.description.length > 40) {
          element.title = element.title.substring(0, 40) + '...';
        }
        if (element.description.length > 100) {
          element.description = element.description.substring(0, 100) + '...';
        }
        let date: any = new Date(element.updated_at);
        let timestamp = Math.floor(date / 1000);
        element.time_ago = timeSince(timestamp, lang);
        if (element.medias.length) {
          element.medias.forEach(media => {
            if (!(media.mime_type.startsWith('audio')) && !element.thumbnail) {
              element.thumbnail = media.url;
            }
          });
        }
        this.pointPublicationsFiltered.push(element);
      }
  
    });
  }

  getUser(id) {
    this.apiService.getAccount(id).subscribe(
      resData => {
        this.user = resData.data;
      },
      errRes => {
        console.log(errRes);
      }
    );
  }

  createFormattedTitle(publication) {
    publication.title_formatted = publication.title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
  }

  loadSingle(publication) {
    if (!this.user && publication.etat == 3) {
      this.publicationWaitingForLogin = publication;
      this.showModalConnect = true;
    } else {
      this.mapService.showPoint('close');
      this.router.navigate([`/publication/${publication.id}/${publication.title_formatted}`]);
    }
  }

  onLogin(form: NgForm) {
    if (!form.valid) { return; }

    this.presentLoading();

    const email = form.value.login;
    const password = form.value.password;

    this.apiService.login(email, password).subscribe(
      resData => {
        this.dismissLoading();
        if (resData.data.account_id) {
          this.mapService.showPoint('close');
          this.router.navigate([`/publication/${this.publicationWaitingForLogin.id}/${this.publicationWaitingForLogin.title_formatted}`]);
        }
      },
      errRes => {
        if (errRes == "Error: unknown e-mail or wrong password") {
          errRes = "Email ou mot de passe incorrect";
        } else if (errRes == "Error: unknown e-mail") {
          errRes = "Email inconnu";
        }
        this.errorMessage = errRes;
        this.dismissLoading();
      }
    );

    form.reset();
  }

  async onRegister(form: NgForm) {
    if (!form.valid) {
      return;
    }

    await this.presentLoading();

    const email = form.value.email;
    const password = form.value.password;
    const passwordRepeat = form.value.password_repeat;
    const firstname = form.value.firstname;
    const name = form.value.name;
    const phone = form.value.phone;
    const adresse = form.value.adresse;
    const zipcode = form.value.zipcode;
    const city = form.value.city;

    if (password !== passwordRepeat) {
      let lang = this.translateService.getBrowserLang();
      if (lang.startsWith('fr')) this.errorMessage = "Les mots de passe ne correspondent pas.";
      if (lang.startsWith('en')) this.errorMessage = "Passwords do not match.";
      if (lang.startsWith('de')) this.errorMessage = "Passwörter stimmen nicht überein.";
      this.dismissLoading();
      return;
    }

    this.apiService.addAccount(email, password, {firstname: firstname, lastname: name, phone: phone, adresse: adresse, zipcode: zipcode, city: city}).subscribe(
      resData => {
        this.apiService.login(email, password).subscribe(
          resData => {
            this.dismissLoading();
            this.mapService.showPoint('close');
            this.router.navigate([`/publication/${this.publicationWaitingForLogin.id}/${this.publicationWaitingForLogin.title_formatted}`]);
          },
          errRes => {
            this.errorMessage = errRes;
            console.log(errRes);
            this.dismissLoading();
          }
        );
        this.dismissLoading();
        this.mapService.showPoint('close');
        this.router.navigate([`/publication/${this.publicationWaitingForLogin.id}/${this.publicationWaitingForLogin.title_formatted}`]);
      },
      errRes => {
        this.errorMessage = errRes;
        console.log(errRes);
        this.dismissLoading();
      }
    );

    form.reset();
  }

  toggleRegister() {
    this.loginFormToggled = false;
    this.registerFormToggled = true;
  }

  toggleLogin() {
    this.loginFormToggled = true;
    this.registerFormToggled = false;
  }

  onCloseModalConnect() {
    this.showModalConnect = false;
  }

}

function timeSince(timestamp, lang) {
  let now: any = new Date();
  let nowTimestamp = Math.floor(now / 1000);
  let seconds = nowTimestamp - timestamp;

  var interval = seconds / 31536000;

  if (lang == 'en') {
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " year ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " month ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " day ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " hour ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return Math.floor(interval) + " minute ago";
      if (Math.floor(interval) > 1) return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  } else {
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " an";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " ans";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " mois";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " mois";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " jour";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " jours";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " heure";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " heures";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) return "Il y a " + Math.floor(interval) + " minute";
      if (Math.floor(interval) > 1) return "Il y a " + Math.floor(interval) + " minutes";
    }
    return "Il y a " + Math.floor(seconds) + " secondes";
  }

}